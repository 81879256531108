<template>
  <div class="showData">
    <div class="top_img">
      <img :src="require('assets/images/showData/bgc.png')" alt="" />
    </div>

    <div class="data_box">
      <div class="item_box">
        <div class="name">今日新增</div>
        <div class="article">
          <span class="count">{{ allData.allContentNumToday }}</span
          ><span class="unit">篇</span>
        </div>
        <div class="infoCompile dot">文库 {{ allData.libraryNumToday }}篇</div>
        <div class="stencil dot">模板 {{ allData.templateNumToday }}篇</div>
        <div class="goldenSentence dot">金句 {{ allData.goldNumToday }}条</div>

        <img
          class="bg"
          :src="require('assets/images/showData/bg1.png')"
          alt=""
        />
      </div>

      <div class="item_box">
        <div class="name">数据总量</div>
        <div class="article">
          <span class="count">{{ allData.allContentNum }}</span
          ><span class="unit">篇</span>
        </div>
        <div class="infoCompile dot">文库 {{ allData.libraryNum }}篇</div>
        <div class="stencil dot">模板 {{ allData.templateNum }}篇</div>
        <div class="goldenSentence dot">金句 {{ allData.goldNum }}条</div>

        <img
          class="bg"
          :src="require('assets/images/showData/bg2.png')"
          alt=""
        />
      </div>

      <div class="item_box">
        <div class="name">算法引擎调用总量</div>
        <div class="article">
          <span class="count">{{
            (allData.recommend || 0) +
            (allData.pictureAI || 0) +
            (allData.screenshotOCR || 0)
          }}</span
          ><span class="unit">次</span>
        </div>
        <div class="infoCompile dot">
          智能推文 {{ allData.recommend || 0 }}次
        </div>
        <div class="stencil dot">AI成图 {{ allData.pictureAI || 0 }}次</div>
        <div class="goldenSentence dot">
          截图OCR {{ allData.screenshotOCR || 0 }}次
        </div>

        <img
          class="bg"
          :src="require('assets/images/showData/bg3.png')"
          alt=""
        />
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import commonFooter from "components/footer/commonFooter.vue";
import { getEsDateNum } from "api/common";
export default {
  name: "showData",
  //import引入的组件需要注入到对象中才能使用
  components: { commonFooter },
  data() {
    //这里存放数据
    return { allData: {} };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getCount() {
      const res = await getEsDateNum();
      this.allData = res.data;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getCount();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.showData {
  position: absolute;
  left: 0;
  margin-top: -30px;
  .top_img {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .data_box {
    display: flex;
    background-color: #f9f9fa;
    padding: 30px 292px;
    justify-content: space-between;
    .item_box {
      width: 426px;
      height: 320px;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: #fff;
      position: relative;

      .name {
        font-size: 16px;

        font-weight: bold;
      }
      .article {
        margin: 25px 0;
        .count {
          font-size: 44px;
          color: #4587ff;
        }
        .unit {
          font-size: 16px;
          color: #999999;
        }
      }
      .infoCompile,
      .stencil,
      .goldenSentence {
        color: #1c1a1d;
        font-size: 16px;
        margin-bottom: 15px;
      }
      .dot {
        position: relative;
        display: flex;
        align-items: center;
      }
      .dot::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #4587ff;
        border-radius: 50%;
        margin-right: 10px;
        // position: absolute;
        // left: -20px;
        // top: 50%;
        // transform: translateY(-50%);
      }

      .bg {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 148px;
      }
    }
  }
}
</style>
